import { UserInfo } from "hooks/useUserInfo";
import { isSenderAgentAssist } from "lib/util";

export const isSessionStartEvent = (message: any): boolean => {
  return message.event === "session_started";
};

export const isSystemEvent = (message: any): boolean => {
  return ["restart", "pause", "resume"].includes(message.event);
};

export const isNoteEvent = (
  message: any,
  userInfo: UserInfo | undefined
): boolean => {
  return (
    userInfo?.id === message?.data?.created_by &&
    message.event === "note_created"
  );
};

export const isLabelAddedEvent = (
  message: any,
  userInfo: UserInfo | undefined
): boolean => {
  return (
    userInfo?.id === message?.data?.created_by &&
    message.event === "label_added"
  );
};

export const isLabelRemovedEvent = (
  message: any,
  userInfo: UserInfo | undefined
): boolean => {
  return (
    userInfo?.id === message?.data?.created_by &&
    message.event === "label_removed"
  );
};

export const isCaseOpenEvent = (
  message: any,
  userInfo: UserInfo | undefined
): boolean => {
  return (
    userInfo?.id === message?.data?.created_by && message.event === "case_open"
  );
};

export const isCaseResolvedEvent = (
  message: any,
  userInfo: UserInfo | undefined
): boolean => {
  return (
    userInfo?.id === message?.data?.created_by &&
    message.event === "case_resolved"
  );
};

export const isCasePendingEvent = (
  message: any,
  userInfo: UserInfo | undefined
): boolean => {
  return (
    userInfo?.id === message?.data?.created_by &&
    message.event === "case_pending"
  );
};

export const isCaseRemindEvent = (
  message: any,
  userInfo: UserInfo | undefined
): boolean => {
  return (
    userInfo?.id === message?.data?.created_by &&
    message.event === "case_remind"
  );
};

export const isOperatorAssignedEvent = (message: any): boolean => {
  return message.event === "operator_assigned";
};

export const isUserEvent = (message: any): boolean => {
  return (
    message.event === "user" &&
    !isSenderAgentAssist(message.sender_id) &&
    !message.metadata?.user_message?.startsWith("/chitchat.greet") &&
    !message.metadata?.user_message?.startsWith("/slot_set") &&
    !message.metadata?.hidden_from_user &&
    !message.metadata?.is_external &&
    message.text !== "/ticket.human_handover.end" &&
    message.text !== "/session.restart"
  );
};

export const isUserTypingEvent = (message: any): boolean => {
  return message.event === "user_typing";
};

export const isHumanHandoverRequestedEvent = (message: any): boolean => {
  return (
    message.event === "slot" &&
    message.name === "hho_handover_is_requested" &&
    message.value === true
  );
};

export const isHumanHandoverAcceptedEvent = (message: any): boolean => {
  return (
    message.event === "slot" && //"hho_operator_user_id"
    message.name === "hho_operator_user_id" &&
    message.value
  );
};

export const isBotEvent = (message: any): boolean => {
  return (
    message.event === "bot" &&
    !isSenderAgentAssist(message.sender_id) &&
    !message.text.startsWith("SYSTEM_MESSAGE: ") &&
    !isOperatorEvent(message)
  );
};

export const isOperatorEvent = (message: any): boolean => {
  return (
    message.event === "bot" && message?.metadata?.event === "operator_uttered"
  );
};

export const isUserEventInConversation = (
  message: any,
  senderId: string
): boolean => {
  return isUserEvent(message) && message?.sender_id?.startsWith(senderId);
};

export const isBotEventInConversation = (
  message: any,
  senderId: string
): boolean => {
  return isBotEvent(message) && message?.sender_id?.startsWith(senderId);
};

export const isUserTypingEventInConversation = (
  message: any,
  senderId: string
): boolean => {
  return isUserTypingEvent(message) && message?.sender_id?.startsWith(senderId);
};
