import React from "react";
import Stack from "@mui/material/Stack";
import Link from "next/link";

import Avatar from "@mui/material/Avatar";

import cls from "./ConversationList/conversation-list.module.scss";
import { parseTimestampToDuration } from "lib/formattedTimestampCalendar";
import Box from "@mui/material/Box";
import classnames from "classnames";
import Typography from "@mui/material/Typography";
import ListItemButton from "@mui/material/ListItemButton";
import { useTranslations } from "next-intl";
import Badge from "@mui/material/Badge";
import { TENANT_SEPARATOR_SYMBOL } from "store/epics/constants";
import Button from "@mui/material/Button";
import getConfig from "lib/config";

const { publicRuntimeConfig } = getConfig();
const { REACT_APP_TENANT } = publicRuntimeConfig;

const ConversationLinkListItem = (props: any) => {
  const { item, tenant, router, previousConversation = false } = props;
  const t = useTranslations();

  const encodedSenderId = encodeURIComponent(
    item.sender_id?.replace(`${TENANT_SEPARATOR_SYMBOL}${tenant}`, "")
  );

  const href = `/${tenant}/conversations/${encodedSenderId}`;
  const conversationMessage =
    item.latest_user_message || item?.metadata?.user_message;

  const unreadMessageCount =
    (item.number_user_messages || 0) - (item.read_messages || 0);

  const contractSenderId = item.contract_id
    ? item.contract_id
    : item.sender_id?.slice(0, 4);

  return (
    <ListItemButton
      selected={item.sender_id === props.senderId}
      sx={{
        width: "100%",
        p: 0,

        ...(previousConversation && {
          backgroundColor: "rgba(54, 0, 82, 0.08)",
          "&:hover": {
            backgroundColor: "rgba(54, 0, 82, 0.18)",
          },
        }),
        ...(previousConversation &&
          REACT_APP_TENANT === "aar" && {
            backgroundColor: "rgba(5, 73, 170, 0.08)",
            "&:hover": {
              backgroundColor: "rgba(5, 73, 170, 0.18)",
            },
          }),
      }}
      alignItems="flex-start"
      onMouseEnter={props.onMouseEnter}
    >
      <Link
        href={href}
        data-for={item.sender_id}
        data-tip=""
        data-iscapture="true"
        passHref
        shallow
      >
        <Box
          className={classnames(
            cls["conversation-list-item"],
            `${router.asPath.includes(href) ? "active" : ""}`
          )}
          sx={{ flexGrow: 1, overflow: "hidden" }}
        >
          <Box sx={{ pr: 2, display: "flex" }}>
            <Avatar
              alt="avatar"
              sx={{
                width: 40,
                height: 40,
                margin: "auto",
                backgroundColor: "secondary.main",
              }}
            />
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              overflow: "hidden",
              "& >div + div": { marginTop: "4px" },
              "&:hover > div:first-of-type > span": {
                overflow: "visible",
              },
            }}
          >
            <Stack
              direction={"row"}
              sx={{
                justifyContent: "space-between",
                alignItems: "center",
                gap: "4px",
              }}
            >
              <Typography
                sx={{
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  lineHeight: "1.2",
                }}
              >
                {/* Show name or email or just an id */}
                {item?.contact?.name ??
                  item?.contact?.email ??
                  `${t("Conversation")} #${item.id}`}
              </Typography>
              <Typography
                variant="caption"
                sx={{
                  color: "grey.500",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
              >
                {parseTimestampToDuration(
                  item.latest_user_message_time || item.timestamp
                )}
              </Typography>
            </Stack>
            <Stack
              direction={"row"}
              sx={{
                justifyContent: "space-between",
                alignItems: "center",
                gap: "4px",
              }}
            >
              <Typography
                variant="subtitle2"
                sx={{
                  color: "grey.600",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {conversationMessage}
              </Typography>
              <Badge
                color={"success"}
                badgeContent={unreadMessageCount}
                max={9}
                sx={{
                  "& .MuiBadge-badge": {
                    position: "static",
                    transform: "none",
                  },
                }}
              />
            </Stack>
          </Box>
        </Box>
      </Link>
    </ListItemButton>
  );
};

export default ConversationLinkListItem;
