import React, { useEffect, useState } from "react";
import { useTranslations } from "next-intl";
import { useRouter } from "next/router";
import { useDispatch } from "react-redux";
import Divider from "@mui/material/Divider";

import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import NotificationsPausedIcon from "@mui/icons-material/NotificationsPaused";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { useStoreState } from "store/hooks";
import { StyledBadge } from "components/common/StyledBadge";
import { isUserEvent } from "lib/events";
import Stack from "@mui/material/Stack";
import ConversationLinkListItem from "features/conversations/components/ConversationLinkListItem";
import { useGlobalWebSocket } from "hooks/useSocket";
import MarkChatReadIcon from "@mui/icons-material/MarkChatRead";
import Box from "@mui/material/Box";
import { TENANT_SEPARATOR_SYMBOL } from "store/epics/constants";
import { isSenderAgentAssist } from "lib/util";

interface NotificationsMenuProps {
  viewingSenderId?: string;
}

export const NotificationsMenu = (props: NotificationsMenuProps) => {
  const t = useTranslations("NotificationsMenu");
  const { viewingSenderId } = props;
  const router = useRouter();
  const { query } = router;
  const tenant = query.tenant as string;
  const dispatch = useDispatch();
  const [notifications, setNotifications] = useState<any>([]);

  const { lastJsonMessage } = useGlobalWebSocket();

  const userSettings = useStoreState((state) => state.userSettings);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (isSenderAgentAssist(lastJsonMessage?.sender_id)) return;
    if (
      lastJsonMessage &&
      isUserEvent(lastJsonMessage) &&
      viewingSenderId !==
        lastJsonMessage?.sender_id?.replace(
          `${TENANT_SEPARATOR_SYMBOL}${tenant}`,
          ""
        )
    ) {
      setNotifications([...notifications, lastJsonMessage]);
    }
  }, [lastJsonMessage]);

  return (
    <>
      <IconButton
        disabled={userSettings?.notificationsPaused}
        aria-controls={open ? "notifications-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <Tooltip
          title={t("new_notifications", { count: notifications.length })}
        >
          <StyledBadge
            badgeContent={
              userSettings?.notificationsPaused ? 0 : notifications.length
            }
            color="error"
            overlap="circular"
            max={9}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            {!userSettings?.notificationsPaused ? (
              <NotificationsNoneIcon />
            ) : (
              <NotificationsPausedIcon />
            )}
          </StyledBadge>
        </Tooltip>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        id="notifications-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            maxHeight: "75%",
            width: 400,
            overflowY: "scroll",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Box>
          <Stack sx={{ px: 2 }}>
            <Typography variant="h5">{t("notifications")}</Typography>
            <Stack direction="row">
              <Typography sx={{ mt: 1, color: "grey.800" }} variant="subtitle1">
                {t("new_notifications", { count: notifications.length })}
              </Typography>
              {notifications.length > 0 && (
                <Tooltip sx={{ ml: "auto" }} title={t("mark_all_as_read")}>
                  <IconButton onClick={() => setNotifications([])}>
                    <MarkChatReadIcon />
                  </IconButton>
                </Tooltip>
              )}
            </Stack>
            {notifications.length > 0 && (
              <>
                <Divider />
                <Typography
                  sx={{ mt: 1, color: "grey.600" }}
                  variant="subtitle2"
                >
                  {t("latest")}
                </Typography>
              </>
            )}
          </Stack>

          {notifications?.length > 0 &&
            notifications.map((notification: any, index: number) => {
              return (
                <React.Fragment key={index}>
                  {isUserEvent(notification) && (
                    <ConversationLinkListItem
                      item={notification}
                      senderId={undefined}
                      tenant={tenant}
                      onClick={() => {
                        setNotifications((prevNotifications: any) =>
                          prevNotifications.filter(
                            (n: any, i: number) => i !== index
                          )
                        );
                      }}
                      router={router}
                    />
                  )}
                  {index !== notifications.length - 1 && <Divider />}
                </React.Fragment>
              );
            })}
        </Box>
      </Menu>
    </>
  );
};
