import dayjs from "dayjs";

export const parseDateToMoment = (date) => {
  return dayjs(date).format("YYYY-MM-DD");
};

export const parseDateToTimestamp = (date) => {
  const datum = Date.parse(date);
  return datum / 1000;
};

export const parseTimestampToDate = (timestamp) => {
  return dayjs(dayjs.unix(timestamp).utc().local()).format("LL");
};

export const parseTimestampToDateWithTime = (timestamp) => {
  return dayjs(dayjs.unix(timestamp).utc().local()).format("LLL");
};

export const parseTimestampToDuration = (timestamp) => {
  return dayjs
    .duration(dayjs.unix(timestamp).utc().local().diff(dayjs()))
    .humanize(true);
};

const getLocalizedCalendarStrings = (t, language) => {
  let localizedStrings;
  language = language ? language : localStorage.getItem("i18nextLng");
  switch (language) {
    case "fr":
      localizedStrings = {
        lastDay: `[${t("date_yesterday_at")}] LT`,
        sameDay: `[${t("date_today_at")}] LT`,
        nextDay: `[${t("date_tomorrow_at")}] LT`,
        lastWeek: `dddd [${t("date_last")}] [${t("date_at")}] LT`,
        nextWeek: `dddd [${t("date_next")}] [${t("date_at")}] LT`,
        sameElse: `DD.MM.YYYY [${t("date_at")}] LT`,
      };
      break;
    default:
      localizedStrings = {
        lastDay: `[${t("date_yesterday_at")}] LT`,
        sameDay: `[${t("date_today_at")}] LT`,
        nextDay: `[${t("date_tomorrow_at")}] LT`,
        lastWeek: `[${t("date_last")}] dddd [${t("date_at")}] LT`,
        nextWeek: `[${t("date_next")}] dddd [${t("date_at")}] LT`,
        sameElse: `DD.MM.YYYY [${t("date_at")}] LT`,
      };
  }

  return localizedStrings;
};

export const formattedUtcDatestringCalendar = (t, language, datestring) => {
  let localizedStrings = getLocalizedCalendarStrings(t, language);
  return dayjs.utc(datestring).local().calendar(null, localizedStrings);
};

export const formattedTimestampCalendar = (
  t,
  language,
  timestamp,
  isUnix = false
) => {
  let localizedStrings = getLocalizedCalendarStrings(t, language);
  let localizedDateTime;
  if (!isUnix) {
    localizedDateTime = dayjs(dayjs.unix(timestamp).utc().local()).calendar(
      null,
      localizedStrings
    );
  } else {
    localizedDateTime = dayjs(timestamp).calendar(null, localizedStrings);
  }
  localizedDateTime =
    localizedDateTime.charAt(0).toUpperCase() + localizedDateTime.slice(1);

  return localizedDateTime;
};
